<template>
  <div>
    <Heard />
    <div class="banner"></div>

    <div class="big-box container-fuild">
      <div class="container">
        <p class="nav">
          <a :href="`/new`">首页</a> >
          <!-- <a :href="`/new`">
            {{
              news.catId == 2
                ? "面试辅导"
                : news.catId == 3
                ? "简历优化"
                : "工作推荐"
            }}</a
          > -->
          > {{ news.title }}
        </p>

        <div class="news-box">
          <div class="box-left">
            <div class="curdeil">
              <h1 class="title">{{ news.title }}</h1>
              <p class="soury">
                <span>来源: {{ news.keywords }}</span>
                <span> 发布时间: {{ news.inputTime }} </span>
              </p>
              <div class="cont-box" v-html="news.content"></div>
            </div>
            <div class="wx">
              <img src="@/assets/image/code1.jpg" alt="" />
              <div>
                想了解更多关于培训,面试的资讯吗？关注职业云联官方微信吧！
                职业云联平台是由原500强企业人力VP陶然先生协同部分人力专家团队，通过近三年自主开发、梳理联合各优质供应商和求职渠道，开发了大量求职辅导、职业规划、
                职场技能提升课程和在线求职辅导服务，同时整合上百家知名企业工作内部推荐渠道，打造出的职场类产品。
              </div>
            </div>
            <div class="next">
              <p v-if="preNews != null">
                上一篇:
                <a
                  :href="`/news/${preNews.id}.html`"
                  :title="preNews.description"
                  >{{ preNews.title }}</a
                >
              </p>
              <p v-if="nextNews != null">
                下一篇:<a
                  :href="`/news/${nextNews.id}.html`"
                  :title="nextNews.description"
                >
                  {{ nextNews.title }}</a
                >
              </p>
            </div>
            <div class="comtit">
              <div></div>
              <p>今日排行</p>
            </div>
            <ul class="today">
              <a
                :href="`/news/${item.id}.html`"
                v-for="item in rightlist.slice(17, 27)"
                :key="item.id"
              >
                <li>· {{ item.title }}</li></a
              >
            </ul>
            <div class="like">
              <div class="like-left">
                <div class="comtit">
                  <div></div>
                  <p>猜你喜欢</p>
                </div>
                <a
                  :href="`/news/${item.id}.html`"
                  v-for="item in rightlist.slice(27, 37)"
                  :key="item.id"
                >
                  <p class="smatit">· {{ item.title }}</p></a
                >
              </div>
              <div class="like-right">
                <div class="comtit">
                  <div></div>
                  <p>今日热点</p>
                </div>
                <a
                  :href="`/news/${item.id}.html`"
                  v-for="item in rightlist.slice(37, 47)"
                  :key="item.id"
                >
                  <p class="smatit">·{{ item.title }}</p></a
                >
              </div>
            </div>
            <div class="comtit">
              <div></div>
              <p>看过该文章的还看了</p>
            </div>
            <div class="tosee">
              <div
                class="toseeitem"
                v-for="item in rightlist.slice(37, 40)"
                :key="item.id"
              >
                <a :href="`/news/${item.id}.html`" :title="item.title">
                  <img :src="item.image" :alt="item.title"
                /></a>
                <a :href="`/news/${item.id}.html`" :title="item.title"
                  ><p>{{ item.title }}</p></a
                >
              </div>
            </div>
            <div class="comtit">
              <div></div>
              <p>相关阅读</p>
            </div>
            <div
              class="left-item"
              v-for="item in rightlist.slice(40, 50)"
              :key="item.id"
            >
              <a :href="`/news/${item.id}.html`" :title="item.title">
                <img :src="item.image" alt=""
              /></a>
              <div class="left-smallbox">
                <a :href="`/news/${item.id}.html`" :title="item.title">
                  <p class="one">{{ item.title }}</p></a
                >
                <p class="two">
                  <a :href="`/news/${item.id}.html`" :title="item.title">
                    {{ item.description }}</a
                  >
                </p>
                <p class="thir">
                  <i class="el-icon-time"></i> 发布时间 : {{ item.inputTime }}
                  <span>
                    <i class="el-icon-user"></i> 作者 :
                    {{ item.keywords }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="box-right">
            <div class="hot">
              <div class="right-nav">
                <div class="line"></div>
                <span>热门推荐</span>
              </div>
              <a
                :href="`/news/${item.id}.html`"
                v-for="item in rightlist.slice(0, 10)"
                :key="item.id"
                :title="item.title"
              >
                <div class="right-content">
                  <p class="tit">{{ item.title }}</p>
                  <p class="time">
                    <i class="el-icon-time"></i>{{ item.inputTime }}
                  </p>
                </div></a
              >
            </div>

            <div class="right-bot">
              <div class="right-nav">
                <div class="line"></div>
                <span>相关推荐</span>
              </div>
              <div class="right-content imgitem">
                <div
                  class="right_item"
                  v-for="item in rightlist.slice(11, 17)"
                  :key="item.id"
                >
                  <a :href="`/news/${item.id}.html`" :title="item.title"
                    ><img :src="item.image" :alt="item.title"
                  /></a>
                  <a
                    class="imgtit"
                    :href="`/news/${item.id}.html`"
                    :title="item.title"
                  >
                    {{ item.title }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import { api_queryNewsDetail, api_queryNewsTitleList } from "@/apis/apis.js";

export default {
  components: {
    Heard,
    Footer,
  },
  data() {
    return {
      id: "",
      totalCount: 1,
      currPage: 1,
      news: {},
      nextNews: {},
      preNews: {},
      rightlist: [],
    };
  },

  async created() {
    console.log(this.$route.params);
    this.id = await Number(this.$route.params.id);

    await this.getdeil();
    await this.getright();
  },

  methods: {
    getdeil() {
      api_queryNewsDetail({
        id: this.id,
      }).then((res) => {
        this.nextNews = res.data.data.nextNew;
        this.preNews = res.data.data.preNew;
        this.totalCount = Math.floor(res.data.data.totalCount / 10);
        const regex = new RegExp("<img", "gi");
        res.data.data.new.content = res.data.data.new.content.replace(
          regex,
          `<img style="max-width: 100%; height: automax-width: 100%;
        height: auto !important;
        margin: 10px auto;
        display: block;"`
        );
        this.news = res.data.data.new;
      });
    },
    getright() {
      api_queryNewsTitleList({
        catId: "",
        pageSize: 60,
      }).then((res) => {
        this.rightlist = res.data.data.dataList;
      });
    },
  },
  watch: {
    //监听当前路由的变化
    $route(newval) {
      //newval：变化后的新数据
      //oldval：变化前的数据
      //   console.log(oldval.params.id, "23131");
      this.id = newval.params.id;
      this.getdeil();
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 302px;
  width: 100%;
  background: url(~@/assets/image/banner2.png) no-repeat center;
}
.container-fluid {
  min-width: 1200px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  height: 302px;
  width: 100%;
  background: url(~@/assets/image/banner2.png) no-repeat center;
}
.big-box {
  background-color: #f5f5f5;
  .nav {
    padding: 15px 0;
    line-height: 50px;
    a {
      color: #53526e;
    }
  }
}
.news-box {
  display: flex;
  .box-left {
    background-color: #fff;
    width: 790px;
    border-radius: 8px;
    padding-bottom: 40px;
    margin-right: 20px;
    padding: 30px;
    .curdeil {
      .title {
        font-size: 22px;
        color: #1b1b1b;
        font-weight: 600;
        line-height: 30px;
        padding: 16px 4px;
      }
      .soury {
        font: 14px/30px "微软雅黑";
        margin-left: 16px;
        span {
          margin-right: 18px;
        }
      }
      .cont-box {
        padding: 20px 16px;
        font: 14px / 1.8 "微软雅黑", "5b8b4f53", sans-serif;
      }
    }

    .wx {
      display: flex;
      align-items: center;
      -padding: 0 40px;
      // background: url(/skin/images/code.jpg) no-repeat 0px 40px;
      height: 120p;
      line-height: 26px;
      background-size: 120px 120px;
      border-top: 1px solid #ccc;
      padding-top: 40px;
      margin-top: 40px;
      img {
        width: 110px;
        height: 110px;
      }
    }
    .next {
      margin: 20px 0;
      p {
        margin-bottom: 6px;
      }
      a {
        color: #ccc;
      }
      a:hover {
        color: #000;
      }
    }
    .comtit {
      color: #5e58fb;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      div {
        width: 4px;
        height: 20px;
        background-color: #5e58fb;
        margin-right: 6px;
      }
    }

    .today {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        width: 45%;
        display: block;
        ul {
          list-style-type: disc !important;
        }
        li {
          width: 100%;
          margin-bottom: 14px;
          color: #7b7b88;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          list-style-type: disc !important;
        }

        li:hover {
          color: #000;
        }
      }
    }

    .like {
      display: flex;
      margin: 40px 0;

      .smatit {
        margin-bottom: 14px;
        color: #7b7b88;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .smatit:hover {
        color: #000;
      }
      .like-left {
        width: 50%;
      }
      .like-right {
        width: 50%;
      }
    }

    .tosee {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .toseeitem {
        width: 240px;
        position: relative;
        img {
          width: 240px;
          height: 160px;
        }

        p {
          width: 240px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    .left-item {
      margin-bottom: 18px;
      display: flex;
      img {
        width: 260px;
        height: 178px;
        margin-right: 18px;
      }

      .left-smallbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .one {
          color: #53526e;
          font: 16px/16px "微软雅黑";
          max-height: 50px;
          overflow: hidden;
          word-break: break-all;
        }

        .two {
          margin-bottom: 40px;
          font-size: 12px;
          color: #999;
          line-height: 25px;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          display: -webkit-box;
          a {
            color: #53526e;
          }
        }

        .thir {
          color: #53526e;
          font-size: 12px;
          span {
            margin-left: 14px;
          }
        }
      }
    }
  }

  .box-right {
    width: 390px;

    .hot {
      background-color: #fff;

      border-radius: 8px;
      padding-bottom: 20px;
      a {
        color: #53526e;
      }
    }

    .right-nav {
      display: flex;

      border-bottom: 1px solid #ccc;
      margin-bottom: 14px;

      .line {
        width: 2px;

        height: 36px;

        margin-top: 12px;

        background-color: #5e58fb;

        margin: 12px 10px 0;
      }

      span {
        color: #999;

        height: 60px;

        line-height: 60px;

        width: 150px;
      }
    }

    .imgitem {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      a {
        color: #53526e;
        width: 50%;
      }
    }
    .right-content {
      padding: 10px;
      .tit {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        font-size: 12px;
        margin-left: 10px;
        margin-top: 8px;
        i {
          margin-right: 6px;
        }
      }
      .right_item {
        width: 48%;
        margin-bottom: 10px;

        img {
          width: 150px;
          height: 103px;
          margin-bottom: 8px;
        }
        .imgtit {
          width: 90%;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .right-bot {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 8px;
      position: sticky;
      padding-bottom: 6px;
      top: 20px;
    }
  }
}

.pages {
  display: flex;
  justify-content: center;
  span {
    margin: 0 16px;
  }
  .all {
    margin-right: 12px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>